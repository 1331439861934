import React from "react";
import Helmet from "../components/Helmet/Helmet.js";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import Type from "../components/Type/Type.jsx";
import Type2 from "../components/Type/Type2.jsx";
import guyImg2 from "../assets/images/delivery.gif";
import "../styles/hero-section.css";
import myImg from "../assets/images/avatar.svg";
import Tilt from "react-parallax-tilt";
import { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Helmet title="Home">
      <Container>
        <Row>
          <Col md="12" lg="6" className="home-header hero__content">
            <h1 style={{ paddingTop: 10 }} className="heading pt-lg-5">
              Hi There!{" "}
              <span className="wave" role="img" aria-labelledby="wave">
                👋🏻
              </span>
            </h1>

            <h1 className="heading-name">
              We are
              <strong className="main-name"> Smart Plate Delivery</strong>
            </h1>
            <div
              style={{
                paddingTop: 20,
                paddingLeft: 50,
                paddingRight: 50,
                paddingBottom: 50,
                textAlign: "left",
              }}
              className="home-header2"
            >
              <Type />
            </div>
            <br />
            <div
              style={{
                paddingTop: 2,
                paddingLeft: 10,
                paddingRight: 50,
                paddingBottom: 2,
                textAlign: "left",
              }}
              className="d-none d-xl-block bottomType"
            >
              <center>
                <h4>For Easy order & fast delivery</h4>
                <Tilt>
                  <button className="order__btn d-flex align-items-center justify-content-between ">
                    <Link to="/packages">
                      Check out our menu{" "}
                      <i className="ri-arrow-right-s-line"></i>
                    </Link>
                  </button>
                </Tilt>
              </center>
            </div>
          </Col>

          <Col lg="6" md="12">
            <div className="hero__img">
              <img src={guyImg2} alt="delivery-guy" className="w-100" />
            </div>
          </Col>
        </Row>
        <Row>
          {" "}
          <Col lg="8" md="7" className="mb-2">
            <div className="hero__content">
              <h1 className="mb-4 hero__title">
                <span>Enjoy</span> home cooked food
              </h1>
              <h4 className="mb-3">Who we are</h4>

              <p style={{ fontWeight: "bold" }}>
                Welcome to Smart Plate Delivery, where we bring delicious,
                healthy meals straight to your offices. Our team of expert chefs
                prepares fresh, nutritious meals every day, using only the
                highest-quality ingredients. Whether you're looking for vegan,
                gluten-free, or low-carb options, we've got you covered. With
                our user-friendly online ordering system, it's easy to customize
                your meal and have it delivered right to your door. Join our
                customers who have made Smart Plate Delivery their go-to choice
                for healthy and convenient meal delivery. Get our packages today
                and taste the difference!
              </p>
            </div>
          </Col>
          <Col lg={4} md={5} className="myAvtar mt:3 mt-md-5 mb-3 mb-md-0">
            <center>
              <Tilt>
                <img src={myImg} className="img-fluid" alt="avatar" />
              </Tilt>
              <Type2 />
            </center>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <center>
              <h4>
                {" "}
                If you have any <span>query Feel free</span> to
              </h4>
              <Tilt>
                <button className="order__btn d-flex align-items-center justify-content-between ">
                  <Link to="/contact">
                    Contact us <i className="ri-arrow-right-s-line"></i>
                  </Link>
                </button>
              </Tilt>
            </center>
          </Col>
        </Row>
      </Container>
    </Helmet>
  );
};

export default Home;
