import sanityClient from "@sanity/client";
const config = {
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET || "production",
  projectId: "t1vnyt2r",
  useCdn: process.env.NODE_ENV === "production",
  token:
    "sksxUWGhKHfETaDHJEYPjfaizgakjqmL9Ujp3Os9j5Wt6rJO0F6iGyKtE69zgGh9dSGXhRWeCezgRo4Xbjf7V5wzXUjJtHmE6CYYSZ1KvmPChZX8XZ4iwQKC6iyu3g3RiT4DEzWFE9eHzU7siEqovDcQ383mz9HfjaSYB1dl55DqXevB8Bdh",
};
const client = sanityClient(config);
export default client;
