import { useState } from "react";
import client from "../client";
import { useEffect } from "react";
import { useAlert } from "react-alert";
import Tilt from "react-parallax-tilt";
import { AiFillCheckCircle } from "react-icons/ai";

import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBTextArea,
} from "mdb-react-ui-kit";
const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [messagePlaced, setmessagePlaced] = useState(false);
  const alert = useAlert();
  function handleSubmit(event) {
    event.preventDefault();
    if (name === "" || description === "" || phone === "") {
      event.stopPropagation();
    } else {
      SendMessage(event);
      setmessagePlaced(true);
    }
  }
  function handleReset(event) {
    event.preventDefault();
    setName("");
    setDescription("");
    setPhone("");
  }
  const SendMessage = async (event) => {
    event.preventDefault();
    const SubscriptionStart = new Date();
    try {
      await client.create({
        _type: "message",
        name,
        phone,
        description,
        SubscriptionStart,
      });
      alert.success("Message successfully sent");

      // handleReset(event);
    } catch (error) {
      console.log(error);
    }
    event.stopPropagation();
  };
  return (
    <div>
      <main id="main" className="mx-auto" style={{ maxWidth: "1200px" }}>
        <div className="" data-aos="fade-in">
          <div className="container">
            <h2>Contact Us</h2>
            <p>
              We would love to hear from you! Whether you have questions about
              our meal delivery service, want to place an order, or have
              feedback to share, we're here to help. You can reach us by phone,
              email, or through our online contact form. Our customer service
              team is available to assist you Monday through Saturday. If you
              have an urgent matter outside of our regular business hours,
              please leave a voicemail or send us an email, and we'll get back
              to you as soon as possible.
            </p>
          </div>
        </div>

        <section id="contact" className="contact">
          <div data-aos="fade-up">
            <iframe
              style={{ border: 0, width: "100%", height: 350 }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3403.2628276648456!2d73.1460289736114!3d31.4619554500293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3922691075c289bf%3A0xd48f8a2cd3c489f8!2sNational%20Textile%20University!5e0!3m2!1sen!2s!4v1683970407901!5m2!1sen!2s"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              title="ntu"
            ></iframe>
          </div>

          <div className="px-4" data-aos="fade-up">
            <div className="row mt-5">
              <div className="col-lg-4">
                <div className="info">
                  <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Location:</h4>
                    <p>
                      {" "}
                      2nd floor BIC NTRC, NTU,Sheikhupura Road, Faisalabad,
                      Punjab
                    </p>
                  </div>

                  <div className="email">
                    <i className="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>smrtplatedelivery@gmail.com</p>
                  </div>

                  <div className="phone">
                    <i className="bi bi-phone"></i>
                    <h4>Call:</h4>
                    <p>+92 3276028098</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-8 col-lg-8 mt-5 mt-lg-0">
                <h1>Contact Us</h1>
                {messagePlaced !== false ? (
                  <div className="checkoutMessage">
                    <div className="checkoutTitleContainer">
                      <AiFillCheckCircle className="checkoutIcon" />
                      <h3>Thank you for message!</h3>
                    </div>
                    <span>
                      Your message is being processed and we will repond to you
                      as fast as possible.
                    </span>
                    <h1>
                      Checkout our Menu for more
                      <br />
                      <center>
                        <Tilt>
                          <button
                            className="order__btn d-flex align-items-center justify-content-between"
                            onClick={() => {
                              setmessagePlaced(false);
                            }}
                          >
                            Send Another message
                            <i className="ri-arrow-right-s-line"></i>
                          </button>
                        </Tilt>
                      </center>
                    </h1>
                  </div>
                ) : (
                  <MDBValidation
                    className="row g-3"
                    onSubmit={handleSubmit}
                    onReset={handleReset}
                  >
                    <MDBValidationItem className="col-md-6">
                      <MDBInput
                        value={name}
                        name="name"
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        id="validationCustom01"
                        required
                        placeholder="Enter your name"
                      />
                    </MDBValidationItem>
                    <MDBValidationItem className="col-md-6">
                      <MDBInput
                        value={phone}
                        name="phone"
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                        id="validationCustom02"
                        required
                        placeholder="enter your phone number"
                      />
                    </MDBValidationItem>

                    <MDBValidationItem
                      className="col-md-12"
                      feedback="Please provide a valid message."
                      invalid
                    >
                      <MDBTextArea
                        value={description}
                        name="description"
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                        id="validationCustom03"
                        required
                        placeholder="Enter your message"
                      />
                    </MDBValidationItem>
                    <div className="col-12">
                      <button class="btn btn-primary" type="submit">
                        Submit form
                      </button>
                      <button class="btn btn-primary" type="reset">
                        Reset Form
                      </button>
                    </div>
                  </MDBValidation>
                )}{" "}
              </div>
            </div>
          </div>
        </section>
        <div className="mb-5">
          <center>
            <h6>
              Thank you for considering Smart Plate Delivery for your meal
              delivery needs. We look forward to serving you!
            </h6>
          </center>
        </div>
      </main>
    </div>
  );
};

export default Contact;
