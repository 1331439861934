import BasicImage from "../images/Basic.jpeg";
import StandardImage from "../images/Standard.jpg";
import PremiumImage from "../images/Premium.jpeg";
import chicken from "../images/chicken.png";
import sabzi from "../images/sabzi.png";
import daal from "../images/daal.png";
import sabzi2 from "../images/sabzi2.png";
import mutton from "../images/mutton.png";
import biryani from "../images/biryani.png";
const products = [
  {
    id: "01",
    title: "Basic Plan (Weekly offer)",
    price: 1500.0,
    price2: 1800.0,
    image01: BasicImage,
    image02: chicken,
    image03: sabzi,
    image04: daal,
    category: "Weekly",
    desc: "Our Basic Plan is perfect for those who are looking for a healthy and convenient meal solution that fits within their budget. This plan includes our delicious and nutritious meals for five/six days a week. The menu changes every week, ensuring that you get a variety of different dishes. The Basic Plan is ideal for those who want to maintain a healthy and balanced diet without compromising on taste or quality.",
  },

  {
    id: "02",
    title: "Basic Plan (Monthly offer)",
    price: 5500.0,
    price2: 6500.0,
    image01: BasicImage,
    image02: chicken,
    image03: sabzi,
    image04: daal,
    category: "Monthly",

    desc: "Our Basic Plan is perfect for those who are looking for a healthy and convenient meal solution that fits within their budget. This plan includes our delicious and nutritious meals for five/six days a week for a whole month. The menu changes every week, ensuring that you get a variety of different dishes. The Basic Plan is ideal for those who want to maintain a healthy and balanced diet without compromising on taste or quality.",
  },

  {
    id: "03",
    title: "Standard Plan (Weekly offer)",
    price: 2200.0,
    price2: 2500.0,
    image01: StandardImage,
    image02: biryani,
    image03: sabzi2,
    image04: daal,
    category: "Weekly",
    desc: "Our Standard Plan is designed for those who are looking for a more varied and premium meal option. This plan includes our delicious and nutritious meals for five/six days a week, with a greater variety of dishes to choose from. The Standard Plan includes a mix of both vegetarian and non-vegetarian dishes, ensuring that there's something for everyone. The Standard Plan is ideal for those who want to indulge in premium and flavorful meals without having to worry about cooking or meal planning.",
  },

  {
    id: "04",
    title: "Standard Plan (Monthly offer)",
    price: 8000.0,
    price2: 9500.0,
    image01: StandardImage,
    image02: biryani,
    image03: sabzi2,
    image04: daal,
    category: "Monthly",

    desc: "Our Standard Plan is designed for those who are looking for a more varied and premium meal option. This plan includes our delicious and nutritious meals for five/six days a week for a whole month, with a greater variety of dishes to choose from. The Standard Plan includes a mix of both vegetarian and non-vegetarian dishes, ensuring that there's something for everyone. The Standard Plan is ideal for those who want to indulge in premium and flavorful meals without having to worry about cooking or meal planning.",
  },

  {
    id: "05",
    title: "Premium Plan (Weekly offer)",
    price: 3400.0,
    price2: 4000.0,
    image01: PremiumImage,
    image02: mutton,
    image03: biryani,
    image04: sabzi,
    category: "Weekly",
    desc: "Our Premium Plan is perfect for those who want to experience the ultimate in convenience and gourmet dining. This plan includes our delicious and nutritious meals for five/six days a week, with a wide range of premium dishes to choose from. The Premium Plan includes a mix of both vegetarian and non-vegetarian dishes, with a focus on high-quality ingredients and gourmet flavors. The Premium Plan is ideal for those who want to indulge in the very best in gourmet dining without having to leave the comfort of their own home.",
  },

  {
    id: "06",
    title: "Premium Plan (Monthly offer)",
    price: 12000.0,
    price2: 14500.0,
    image01: PremiumImage,
    image02: mutton,
    image03: biryani,
    image04: sabzi,
    category: "Monthly",

    desc: "Our Premium Plan is perfect for those who want to experience the ultimate in convenience and gourmet dining. This plan includes our delicious and nutritious meals for five/six days a week for a whole month, with a wide range of premium dishes to choose from. The Premium Plan includes a mix of both vegetarian and non-vegetarian dishes, with a focus on high-quality ingredients and gourmet flavors. The Premium Plan is ideal for those who want to indulge in the very best in gourmet dining without having to leave the comfort of their own home.",
  },
];

export default products;
