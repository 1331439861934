import "../styles/checkout.css";
import { AiFillCheckCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { cartActions } from "../store/shopping-cart/cartSlice";
import { Link } from "react-router-dom";
import client from "../client";
import { useAlert } from "react-alert";
import { useEffect } from "react";
import Tilt from "react-parallax-tilt";
import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBTextArea,
} from "mdb-react-ui-kit";
const Checkout = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const cartProducts = useSelector((state) => state.cart.cartItems);
  const totalAmount = useSelector((state) => state.cart.totalAmount);
  const [orderPlaced, setOrderdPlaced] = useState(false);
  const [name, setName] = useState("");
  const [loader, setLoader] = useState(false);
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();
  const alert = useAlert();

  function handleSubmit(event) {
    event.preventDefault();
    setLoader(true);
    if (name === "" || address === "" || phone === "") {
      event.stopPropagation();
      setLoader(false);
    } else {
      deleteItem(event);
    }
  }

  const deleteItem = async (event) => {
    event.preventDefault();
    console.log(loader);
    const price = totalAmount;
    const SubscriptionStart = new Date();

    const allpackages = cartProducts.map((obj) => obj.title);
    const allpackagesnumber = cartProducts.map((obj) => obj.quantity);
    try {
      await client.create({
        _type: "client",
        name,
        phone,
        address,
        description,
        allpackages,
        allpackagesnumber,
        price,
        SubscriptionStart,
      });
      alert.success("Order successfully placed");
    } catch (error) {
      console.log(error);
    }

    dispatch(cartActions.deleteCart());
    setOrderdPlaced(true);
    setLoader(false);
    event.stopPropagation();
  };
  return (
    <>
      {orderPlaced !== false ? (
        <div className="checkoutMessage">
          <div className="checkoutTitleContainer">
            <AiFillCheckCircle className="checkoutIcon" />
            <h3>Thank you for your order!</h3>
          </div>
          <span>
            Your order is being processed and will be delivered as fast as
            possible.
          </span>
          <h1>
            Checkout our Menu for more
            <br />
            <center>
              <Tilt>
                <button className="order__btn d-flex align-items-center justify-content-between ">
                  <Link to="/packages">
                    Continue More Shopping
                    <i className="ri-arrow-right-s-line"></i>
                  </Link>
                </button>
              </Tilt>
            </center>
          </h1>
        </div>
      ) : (
        <div className="checkoutMessage1">
          <div className="cart__item-list">
            <div className="row md:mx-5 mx-2">
              <div className="col-12 col-md-6">
                <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Package</th>
                      <th scope="col">Price</th>
                      <th scope="col">Qunatity</th>
                    </tr>
                  </thead>
                  {cartProducts.length === 0 ? (
                    <div>
                      <br />
                      <h6 className="text-center"> Empty Cart</h6>
                    </div>
                  ) : (
                    cartProducts.map((item, index) => (
                      <tbody>
                        <tr>
                          <th scope="row">{index + 1}</th>

                          <td>{item.title}</td>
                          <td>
                            <center>{item.price}</center>
                          </td>
                          <td>
                            <center> x{item.quantity}</center>
                          </td>
                        </tr>
                      </tbody>
                    ))
                  )}
                </table>
              </div>
              <div className="col-12 col-md-6 mt-2">
                {/* <form>
                  <div className="form-group pb-2">
                    <input
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter Your Name"
                      type="text"
                      name="name"
                      required
                    />
                  </div>

                  <div className="form-group pb-2">
                    <input
                      className="form-control"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder="Enter your phone number"
                      type="text"
                      name="phone"
                      required
                    />
                  </div>

                  <div className="form-group pb-2">
                    <input
                      className="form-control"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      placeholder="Please enter your address"
                      type="text"
                      name="address"
                      required
                    />
                  </div>

                  <div className="form-group pb-2">
                    <textarea
                      className="form-control"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder="Please enter some additional information of you want"
                      name="description"
                      rows="4"
                    />
                  </div>

                  <div className="cart__bottom2 d-flex align-items-center justify-content-between">
                    <h6>
                      Subtotal : <span>${totalAmount}</span>
                    </h6>
                    {loader === false ? (
                      <>
                        {" "}
                        <button
                          type="submit"
                          onClick={(event) => deleteItem(event)}
                        >
                          Submit
                        </button>
                      </>
                    ) : (
                      <>
                        <button type="button" disabled>
                          <span
                            class="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Loading...
                        </button>
                      </>
                    )}
                  </div>
                </form> */}
                <h1>Customer Info</h1>
                <hr />
                <MDBValidation className="row g-3" onSubmit={handleSubmit}>
                  <MDBValidationItem className="col-md-12">
                    <MDBInput
                      value={name}
                      name="name"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      id="validationCustom01"
                      required
                      placeholder="Enter your name"
                    />
                  </MDBValidationItem>
                  <MDBValidationItem className="col-md-12">
                    <MDBInput
                      value={phone}
                      name="phone"
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                      id="validationCustom02"
                      required
                      placeholder="enter your phone number"
                    />
                  </MDBValidationItem>
                  <MDBValidationItem className="col-md-12">
                    <MDBInput
                      value={address}
                      name="address"
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                      id="validationCustom02"
                      required
                      placeholder="enter your address"
                    />
                  </MDBValidationItem>

                  <MDBTextArea
                    value={description}
                    name="description"
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    id="validationCustom03"
                    placeholder="Enter your message"
                  />
                  <div className="cart__bottom2 d-flex align-items-center justify-content-between">
                    <h6>
                      Subtotal : <span>pkr {totalAmount}</span>
                    </h6>
                    {loader === false ? (
                      <>
                        {" "}
                        <button type="submit">Submit</button>
                      </>
                    ) : (
                      <>
                        <button type="button" disabled>
                          <span
                            class="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Loading...
                        </button>
                      </>
                    )}
                  </div>
                </MDBValidation>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Checkout;
