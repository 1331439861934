import React from "react";
import Typewriter from "typewriter-effect";

function Type2() {
  return (
    <Typewriter
      options={{
        strings: [
          "Any Query? contact us now!!!",
          "Click on the button below !",
          "So what are you waiting for ?",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 30,
        delay: 65,
      }}
    />
  );
}

export default Type2;
