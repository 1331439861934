import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Looking for home made food at your doorstep without any hustle? ",
          "Welcome to smart plate delivery where we provide quality food at affordable prices",
          "So what are you waiting for?",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 30,
        delay: 65,
      }}
    />
  );
}

export default Type;
