import React, { useState, useEffect } from "react";
import products from "../assets/fake-data/products";
import { useAlert } from "react-alert";
import Tilt from "react-parallax-tilt";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useParams } from "react-router-dom";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/common-section/CommonSection";
import { Container, Row, Col } from "reactstrap";
import ExtraIngredient from "../components/ExtraIngredient/ExtraIngredient.jsx";
import { useDispatch } from "react-redux";
import { cartActions } from "../store/shopping-cart/cartSlice";
import { useSelector } from "react-redux";
import guyImg from "../assets/images/delivery-guy.png";
import "../styles/product-details.css";
import "../styles/product-card.css";

import ProductCard from "../components/UI/product-card/ProductCard";

const ExtraIngredients = {
  MUSHROOMS: "Mushrooms",
  ONION: "Onion",
  PEPPER: "Pepper",
  PINAPPLE: "Pinapple",
  TUNA: "Tuna",
  MEAT: "Meat",
  CHEESE: "Cheese",
  HOTSAUCE: "Hot Sauce",
  CORN: "Corn",
};

const PackageDetails = () => {
  const { id } = useParams();
  const alert = useAlert();

  const dispatch = useDispatch();
  const [extraIngredients, setExtraIngredients] = useState([]);
  const [isUpdateNotificationDisplayed, setIsUpdateNotificationDisplayed] =
    useState(false);
  const product = products.find((product) => product.id === id);
  const cartProducts = useSelector((state) => state.cart.cartItems);
  const [previewImg, setPreviewImg] = useState(product.image01);
  let { title, price, price2, category, desc, image01 } = product;
  // const [price, setPrice] = useState(price);
  const relatedProduct = products.filter((item) => category === item.category);

  useEffect(() => {
    const existingFood = cartProducts.find((item) => item.id === id);
    if (existingFood) {
      setExtraIngredients(existingFood.extraIngredients);
    } else {
      setExtraIngredients([]);
    }
  }, [cartProducts, id]);

  const addItem = () => {
    setIsUpdateNotificationDisplayed(true);
    setTimeout(function () {
      setIsUpdateNotificationDisplayed(false);
    }, 3000);

    dispatch(
      cartActions.addItem({
        id,
        title,
        price,
        image01,
        extraIngredients,
      })
    );
    alert.success("Product added successfully");
  };
  const addItem2 = () => {
    setIsUpdateNotificationDisplayed(true);
    setTimeout(function () {
      setIsUpdateNotificationDisplayed(false);
    }, 3000);
    price = price2;

    dispatch(
      cartActions.addItem({
        id,
        title,
        price,
        image01,
        extraIngredients,
      })
    );
    alert.success("Product added successfully");
  };

  useEffect(() => {
    setPreviewImg(product.image01);
    window.scrollTo(0, 0);
  }, [product]);
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Click now
    </Tooltip>
  );
  function updateExtraIngredients(ingredient) {
    // if(extraIngredients.includes(ingredient)) {
    //   setExtraIngredients(extraIngredients.filter(item => item !== ingredient));
    // } else {
    //   setExtraIngredients(previousState => [...previousState, ingredient]);
    // }
  }

  return (
    <Helmet title="Product-details">
      {/* {isUpdateNotificationDisplayed && (
        <div className="updateCartNotifiation">
          <span>Item successfully added to your cart!</span>
        </div>
      )} */}

      <CommonSection title={title} />

      <section>
        <Container>
          <Row>
            <Col lg="2" md="2">
              <center>
                <div className="product__images">
                  <div
                    className="img__item mb-3"
                    onClick={() => setPreviewImg(product.image01)}
                  >
                    <img
                      src={product.image01}
                      alt=""
                      className="w-50 myCursor"
                      height={"65px"}
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                  <div
                    className="img__item mb-3"
                    onClick={() => setPreviewImg(product.image02)}
                  >
                    <img
                      src={product.image02}
                      alt=""
                      className="w-50 myCursor"
                      height={"65px"}
                      style={{ objectFit: "cover" }}
                    />
                  </div>

                  <div
                    className="img__item mb-3 myCursor"
                    onClick={() => setPreviewImg(product.image03)}
                  >
                    <img
                      src={product.image03}
                      alt=""
                      className="w-50 myCursor"
                      height={"65px"}
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                  <div
                    className="img__item "
                    onClick={() => setPreviewImg(product.image04)}
                  >
                    <img
                      src={product.image04}
                      alt=""
                      className="w-50 myCursor"
                      height={"65px"}
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                </div>
              </center>
            </Col>

            <Col lg="4" md="4">
              <center>
                <div className="product__main-img mt-md-4">
                  <img
                    src={previewImg}
                    alt=""
                    className="w-100"
                    height={"250px"}
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </center>
            </Col>

            <Col lg="6" md="6" className="md-pr-0 pr-5">
              <center>
                <div className="single__product-content">
                  <h2 className="product__title mb-3">{title}</h2>
                  <>
                    <Row>
                      <Col>
                        <p className="category">
                          Category: <span>{category}</span>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="product__price">
                          {" "}
                          Price: <span>pkr {price}</span>
                        </p>
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip}
                        >
                          <Button
                            variant="danger"
                            className="addTOCART__btn"
                            onClick={addItem}
                          >
                            Get This Package
                          </Button>
                        </OverlayTrigger>
                        <p className="mt-2">For 5 days</p>
                      </Col>
                      <Col>
                        <p className="product__price">
                          {" "}
                          Price: <span>pkr {price2}</span>
                        </p>
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip}
                        >
                          <Button
                            variant="danger"
                            className="addTOCART__btn"
                            onClick={addItem2}
                          >
                            Get This Package
                          </Button>
                        </OverlayTrigger>
                        <p className="mt-2">For 6 days</p>
                      </Col>
                    </Row>
                  </>
                </div>
              </center>
            </Col>
            {id == 1 || id == 2 ? (
              <>
                {" "}
                <Col lg="8">
                  <h1 className="mt-4">Food Menu for Basic</h1>
                  <div
                    className="table-responsive mx-auto"
                    style={{ maxWidth: "900px" }}
                  >
                    <table className="table">
                      <thead>
                        <tr className="table-dark">
                          <th scope="col">Food Item</th>
                          <th scope="col">Days</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="">
                          <td>Chicken</td>
                          <td>1</td>
                        </tr>
                        <tr>
                          <td>Rice</td>
                          <td>1</td>
                        </tr>
                        <tr className="">
                          <td>Daal</td>
                          <td>1-2</td>
                        </tr>
                        <tr>
                          <td>Sabzi</td>
                          <td>1-2</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
              </>
            ) : id == 3 || id == 4 ? (
              <>
                <Col lg="8">
                  <h1 className="mt-4">Food Menu for Standard</h1>
                  <div
                    className="table-responsive mx-auto"
                    style={{ maxWidth: "900px" }}
                  >
                    <table className="table">
                      <thead>
                        <tr className="table-dark">
                          <th scope="col">Food Item</th>
                          <th scope="col">Days</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="">
                          <td>Chicken/Beef</td>
                          <td>1-2</td>
                        </tr>
                        <tr>
                          <td>Rice</td>
                          <td>1</td>
                        </tr>
                        <tr className="">
                          <td>Daal</td>
                          <td>1-2</td>
                        </tr>
                        <tr>
                          <td>Sabzi</td>
                          <td>1-2</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
              </>
            ) : (
              <>
                {" "}
                <Col lg="8">
                  <h1 className="mt-4">Food Menu for Premium</h1>
                  <div
                    className="table-responsive mx-auto"
                    style={{ maxWidth: "900px" }}
                  >
                    <table className="table">
                      <thead>
                        <tr className="table-dark">
                          <th scope="col">Food Item</th>
                          <th scope="col">Days</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="">
                          <td>Chicken/Beef/Mutton</td>
                          <td>1-3</td>
                        </tr>
                        <tr>
                          <td>Rice</td>
                          <td>1</td>
                        </tr>
                        <tr className="">
                          <td>Daal</td>
                          <td>1-2</td>
                        </tr>
                        <tr>
                          <td>Sabzi</td>
                          <td>1-2</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
              </>
            )}

            <Col lg="4" className="mt-5">
              <div className="mt-5 d-none d-xl-block">
                <Tilt>
                  {" "}
                  <img src={guyImg} alt="delivery-guy" className="w-100" />
                </Tilt>
              </div>
            </Col>
            {/* <Col lg="12">
              <div className="extraIngredientsGrid">
                {Object.values(ExtraIngredients).map((ingredient) => {
                  return (
                    <></>
                    // <ExtraIngredient
                    //   isChecked={extraIngredients.includes(ingredient)}
                    //   key={ingredient}
                    //   onSelect={(ingredient) =>
                    //     updateExtraIngredients(ingredient)
                    //   }
                    //   ingredient={ingredient}
                    // ></ExtraIngredient>
                  );
                })}
              </div>
            </Col> */}

            <Col lg="12">
              <h6 className="description">Description</h6>
              <div className="description__content">
                <p>{desc}</p>
              </div>
            </Col>

            <Col lg="12" className="mb-5 mt-4">
              <h2 className="related__Product-title">You might also like</h2>
            </Col>

            {relatedProduct.map((item) => (
              <Col lg="3" md="4" sm="6" xs="6" className="mb-4" key={item.id}>
                <ProductCard item={item} />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default PackageDetails;
