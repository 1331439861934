import React from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "../../../styles/product-card.css";
import { useAlert } from "react-alert";
// import productImg from "../../../assets/images/product_2.1.jpg";

import { useDispatch } from "react-redux";
import { cartActions } from "../../../store/shopping-cart/cartSlice";

import { Link } from "react-router-dom";

const ProductCard = (props) => {
  const { id, title, image01, price, price2, extraIngredients } = props.item;
  const dispatch = useDispatch();
  const alert = useAlert();
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Click now
    </Tooltip>
  );
  const addToCart = () => {
    dispatch(
      cartActions.addItem({
        id,
        title,
        image01,
        price,
        extraIngredients,
      }),
      alert.success("Package added successfully")
    );
  };

  return (
    <div className="product__item d-flex flex-column justify-content-between">
      <Link to={`/packages/${id}`}>
        <div className="product__content">
          <img
            className="product__img w-100 myCursor"
            height={"100px"}
            src={image01}
            alt="Food"
            style={{ objectFit: "cover" }}
          />
          <h5 className="myCursor">{title}</h5>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-between">
          <span className="product__price mb-2 myCursor">
            {price}pkr(5 days/week)
          </span>
          <span className="product__price mb-2 myCursor">
            {price2}pkr(6 days/week)
          </span>
        </div>
      </Link>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <Button variant="danger" className="addTOCART__btn" onClick={addToCart}>
          Get This Package
        </Button>
      </OverlayTrigger>
    </div>
  );
};

export default ProductCard;
