import React from "react";
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { MDBIcon } from "mdbreact";
import logo from "../../assets/images/res-logo.png";
import "../../styles/footer.css";
import { NavLink, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
const Footer = () => {
  let navigate = useNavigate();

  return (
    <>
      <MDBFooter
        className="text-center text-lg-start text-muted pt-5"
        style={{ backgroundColor: "#fde4e4" }}
      >
        <MDBContainer className="text-center text-md-start">
          <MDBRow>
            <MDBCol md="3" lg="3" xl="3" className="mx-auto">
              <h6 className="text-uppercase fw-bold mb-4 mt-md-4">
                Useful links
              </h6>
              <p className="myLogo">
                <a
                  href="#!"
                  className="text-reset myLogo"
                  onClick={() => navigate("/home")}
                >
                  Home Page
                </a>
              </p>
              <p className="myLogo">
                <a
                  href="#!"
                  className="text-reset"
                  onClick={() => navigate("/packages")}
                >
                  Get Packages
                </a>
              </p>
              <p className="myLogo">
                <a
                  href="#!"
                  className="text-reset"
                  onClick={() => navigate("/contact")}
                >
                  Contact Us Page
                </a>
              </p>
              <p className="myLogo">
                <a
                  href="#!"
                  className="text-reset"
                  onClick={() => navigate("/cart")}
                >
                  Check your Cart
                </a>
              </p>
            </MDBCol>
            <MDBCol md="5" lg="6" xl="6" className="mx-auto">
              <center>
                <h6 className="text-uppercase fw-bold mb-4">
                  <div className="footer__logo">
                    <img src={logo} alt="logo" /> Smart Plate Delivery
                  </div>
                </h6>
                <div className="px-4">
                  <hr />
                </div>
                <p>
                  At SmartPlate Delivery, we are committed to using only the
                  freshest and highest quality ingredients to create delicious
                  and nutritious meals that are both satisfying and healthy.
                </p>
              </center>
            </MDBCol>
            <MDBCol
              md="4"
              lg="3"
              xl="3"
              className="mx-auto myCursor"
              onClick={() => navigate("/contact")}
            >
              <h6 className="text-uppercase fw-bold mb-4 mt-md-4 myCursor">
                Contact
              </h6>
              <p>
                <div className="hover-overlay myCursor myLogo">
                  <MDBIcon icon="building" className="me-3 myLogo myCursor" />
                  2nd floor BIC NTRC, NTU,Sheikhupura Road, Faisalabad, Punjab
                </div>
              </p>
              <p className="myCursor myLogo">
                <MDBIcon
                  icon="envelope"
                  className="me-3 myLogo myCursor"
                  /*style={{ color: "#df2020" }}*/
                />
                smrtplate@gmail.com
              </p>
              <p className="myCursor myLogo">
                <MDBIcon
                  icon="phone"
                  className="me-3 myLogo myCursor"
                  /*style={{ color: "#df2020" }}*/
                />{" "}
                +92 3276028098
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <hr />
        <div>
          <center>
            <h5 className="footer__title mb-3">Delivery Time</h5>

            <div className="delivery__time-item border-0 ps-0">
              <span>Monday - Saturday</span>
              <p>1:00pm - 2:00pm</p> <p>/</p>
              <p>8:00pm - 9:00pm</p>
            </div>
          </center>
          <section
            className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom mx-auto"
            style={{ maxWidth: "1500px" }}
          >
            <div className="me-5 d-none d-lg-block">
              <span>Get connected with us on social networks:</span>
            </div>
            <div>
              <a
                href="https://www.facebook.com/checkpoint/1501092823525282/?next=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100092400535271"
                className="me-4 text-reset myCursor"
                target={"_blank"}
              >
                <MDBIcon
                  fab
                  icon="facebook-f" /*style={{ color: "#df2020" }}*/
                  className="FbmyLogo myCursor"
                />
              </a>
              <a
                href="https://twitter.com/smrtplate"
                className="me-4 text-reset myCursor"
                target={"_blank"}
              >
                <MDBIcon
                  fab
                  icon="twitter"
                  className="TwmyLogo myCursor"
                  /*style={{ color: "#df2020" }}*/
                />
              </a>
              <a
                href="https://wa.me/message/OSFNN7YUEBI4K1"
                className="me-4 text-reset myCursor"
                target={"_blank"}
              >
                <MDBIcon
                  fab
                  icon="whatsapp"
                  className="GomyLogo myCursor" /*style={{ color: "#df2020" }}*/
                />
              </a>
              <a
                href="https://www.instagram.com/smrtplatedelivery/?igshid=ZGUzMzM3NWJiOQ%3D%3D"
                className="me-4 text-reset myCursor"
                target={"_blank"}
              >
                <MDBIcon
                  fab
                  icon="instagram"
                  className="InmyLogo myCursor"
                  target={"_blank"}

                  /*style={{ color: "#df2020" }}*/
                />
              </a>
              <a
                href="https://www.linkedin.com/in/smart-plate-821989276/"
                className="me-4 text-reset myCursor"
                target={"_blank"}
              >
                <MDBIcon
                  fab
                  icon="linkedin"
                  className="LimyLogo myCursor" /*style={{ color: "#df2020" }}*/
                />
              </a>
              <a className="ml-4"> </a>
            </div>
          </section>
        </div>
      </MDBFooter>
    </>
  );
};

export default Footer;
