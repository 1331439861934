import Layout from "./components/Layout/Layout";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
function App() {
  const options = {
    timeout: 5000,
    position: positions.BOTTOM_CENTER,
  };
  return (
    <Provider template={AlertTemplate} {...options}>
      <Layout />
    </Provider>
  );
}

export default App;
